export const addPageStyles = (styleUrls: string[]) => {
  const head = document.head || document.getElementsByTagName('head')[0];

  for (const [index, url] of styleUrls.entries()) {
    const style = document.createElement('link');
    style.id = `custom-style-${index}`;
    style.rel = 'stylesheet';
    style.href = url;
    style.type = 'text/css';

    head.appendChild(style);
  }

  // const style = document.createElement('link');
  // style.rel = 'stylesheet';
  // style.href = `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/navigation.css`;

  // head.appendChild(style);

  // style.type = 'text/css';
  // // @ts-ignore
  // if (style.styleSheet) {
  //   // This is required for IE8 and below.
  //   // @ts-ignore
  //   style.styleSheet.cssText = css;
  // } else {
  //   style.appendChild(document.createTextNode(css));
  // }
};
