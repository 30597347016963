import React, { useEffect } from 'react';
import { Box, Theme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AuthenticatedSetup } from 'Components/AuthenticatedSetup';
import { useStyles } from '../styles';
import s from '@components/common/Layout/Layout.module.css';
import classNames from 'classnames';
import { withDemiplaneUrqlClient } from 'Hocs/WithDemiplaneUrqlClient';
import { DemiplaneTopNavigation } from 'Components/TopNavigation/Demiplane/DemiplaneTopNavigation';
import { RecoilRootProvider } from 'src/state/providers/RecoilRootProvider';
import { SnackbarProvider } from 'notistack';
import { DefaultSeo } from 'next-seo';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { RxJsEmitterProvider } from 'src/state/providers/RxJsEmitterProvider';
import { dynamicImport } from 'src/lib/dynamicImport';
import { useRouter } from 'next/router';
import { useCheckLogin } from 'src/utils/checkLogin';

const RouteListener = dynamicImport(
  'RouteListener',
  () => import('Components/RouteListener').then((mod) => mod.RouteListener),
  { ssr: false }
);

const DrawerController = dynamicImport(
  'DrawerController',
  () =>
    import('Components/DrawerController').then((mod) => mod.DrawerController),
  { ssr: false }
);

const WindowResize = dynamicImport(
  'WindowResize',
  () => import('Components/WindowResize').then((mod) => mod.WindowResize),
  { ssr: false }
);

interface IAuthenticatedLayoutProps {
  theme?: Theme;
  children?: any;
  pageProps: any;
}

const AuthenticatedLayoutBase = ({
  theme,
  children,
  pageProps,
}: IAuthenticatedLayoutProps) => {
  const classes = useStyles();
  const tempClasses = [classes.appContainer, s.root];
  const router = useRouter();
  const { checkLogin } = useCheckLogin(
    router,
    pageProps?.topNav?.currentUser?.id
  );

  useEffect(() => {
    const clearRegistrationLocalStorage = async () => {
      if (!!pageProps.topNav.currentUser) {
        window.localStorage.removeItem('account-setup-form-1');
        window.localStorage.removeItem('account-setup-form-2');
        window.localStorage.removeItem('account-setup-form-3');
      }
    };

    clearRegistrationLocalStorage();
  }, [pageProps.topNav.currentUser]);

  const cn = classNames(tempClasses);

  useEffect(() => {
    if (!!pageProps.topNav?.currentUser?.id) {
      window.dataLayer?.push({
        event: 'identify',
        userId: `${pageProps.topNav.currentUser.id}`,
      });
    }
  }, [pageProps.topNav?.currentUser]);

  useEffect(() => {
    let isMounted = true;
    checkLogin();

    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  }, [checkLogin]);

  if (!!theme) {
    return (
      <>
        <DefaultSeo
          title='Demiplane'
          description=''
          openGraph={{
            url: '/',
            title: 'Demiplane',
            description:
              'Find Your Next Adventure On Demiplane - Matchmaking, Video, Voice, Shared Journaling, And Much More To Come!',
            type: 'website',
            images: [
              {
                url: 'https://demiplane-static.s3-us-west-2.amazonaws.com/DemiplaneOG.jpg',
                alt: 'Demiplane',
              },
            ],
            site_name: 'Demiplane',
          }}
          twitter={{
            handle: '@DemiplaneRPG',
            cardType: 'summary_large_image',
          }}
        />
        <WindowResize />
        <RouteListener />
        <DrawerController />
        <AuthenticatedSetup />
        <Box className={cn}>
          <ThemeProvider theme={theme}>
            <DemiplaneTopNavigation topNav={pageProps.topNav} />

            <Box
              sx={{
                height: {
                  xs: 'calc(100dvh - 72px)',
                  [`@media (minWidth:1365px)`]: 'calc(100dvh - 80px)',
                },
              }}
              position={'relative'}
            >
              {children}
            </Box>
          </ThemeProvider>
        </Box>
      </>
    );
  }

  return (
    <>
      <DefaultSeo
        title='Demiplane'
        description=''
        openGraph={{
          url: '/',
          title: 'Demiplane',
          description:
            'Find Your Next Adventure On Demiplane - Matchmaking, Video, Voice, Shared Journaling, And Much More To Come!',
          type: 'website',
          images: [
            {
              url: 'https://demiplane-static.s3-us-west-2.amazonaws.com/DemiplaneOG.jpg',
              alt: 'Demiplane',
            },
          ],
          site_name: 'Demiplane',
        }}
        twitter={{
          handle: '@DemiplaneRPG',
          cardType: 'summary_large_image',
        }}
      />
      <WindowResize />
      <RouteListener />
      <DrawerController />
      <AuthenticatedSetup />
      <Box className={cn}>
        <DemiplaneTopNavigation topNav={pageProps.topNav} />
        <Box
          sx={{
            height: {
              xs: 'calc(100dvh - 72px)',
              [`@media (minWidth:1365px)`]: 'calc(100dvh - 80px)',
            },
          }}
          position={'relative'}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export const AuthenticatedLayout = withDemiplaneUrqlClient(
  (props: IAuthenticatedLayoutProps) => (
    <UserProvider>
      <RecoilRootProvider>
        <RxJsEmitterProvider>
          <SnackbarProvider maxSnack={3}>
            <AuthenticatedLayoutBase {...props} />
          </SnackbarProvider>
        </RxJsEmitterProvider>
      </RecoilRootProvider>
    </UserProvider>
  )
);
